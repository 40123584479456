import React from 'react'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import Typography from '../common/Typography'

import useBreakpoint from '../../hooks/useBreakpoint'

import { DogProps, DogContent } from '../../interfaces/team'

export default function DogGrid({ content }: DogProps): JSX.Element {
  const { t } = useTranslation()
  const isDesktop = useBreakpoint('sm')

  const DogsList = content.map((dog: { node: DogContent }) => (
    <StyledDogGridTile key={uuidv4()} order={dog.node.order}>
      {dog.node.photo && (
        <StyledDogPhoto
          src={dog.node.photo.localFile.publicURL}
          height="220"
          width="220"
        />
      )}
      <StyledDogData type={isDesktop ? 'h7' : 'copyBig'}>
        {dog.node.name}
      </StyledDogData>
    </StyledDogGridTile>
  ))
  return (
    <StyledDogSection>
      <StyledDogGridTitle type={isDesktop ? 'h2' : 'h2Mobile'}>
        {t('team.dogs.title')}
      </StyledDogGridTitle>
      <StyledDogGridContainer>{DogsList}</StyledDogGridContainer>
    </StyledDogSection>
  )
}

const StyledDogSection = styled.section`
  text-align: left;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 4rem 0 0;
  padding: 0 2rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    max-width: 100rem;
    padding: 0 4rem;
    margin: 9rem auto;
  }
`

const StyledDogGridTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.accentLight};
  font-weight: 500;
  text-align: left;
  padding: 3rem 0;
`

const StyledDogGridContainer = styled.article`
  display: grid;
  z-index: 2;
  width: 100%;
  grid-template-columns: 50% 50%;
  justify-content: center;
  justify-items: center;
  grid-gap: 1.5rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 2rem auto 0;
    grid-template-columns: 25% 25% 25% 25%;
    grid-row-gap: 3rem;
    grid-column-gap: 1.5rem;
  }
`

const StyledDogGridTile = styled.div<{
  order: number
}>`
  width: 100%;
  max-width: 22rem;
  line-height: 3.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.global.accent};
  background-color: ${({ theme }) => theme.palette.global.white};
  box-shadow: 0px 13px 40px 5px rgba(116, 134, 169, 0.2);
  border-radius: 4rem;
  text-align: left;
  padding: 2rem 2rem;
  order: ${({ order }) => order};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    line-height: 5.2rem;
    padding: 2rem 3rem;
    min-height: 22rem;
  }
`

const StyledDogPhoto = styled.img`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.shades.grey};
  width: 70%;
  max-width: 11rem;
  height: auto;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 80%;
  }
`

const StyledDogData = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.accentLight};
  font-weight: 700;
`
