import React from 'react'
import { graphql } from 'gatsby'
import i18next from 'i18next'

import Layout from '../components/Layout/Layout'
import Hero from '../components/common/Hero/Hero'
import TeamGrid from '../components/Team/TeamGrid'
import DogGrid from '../components/Team/DogGrid'
import PlanetTeam from '../components/common/Hero/PlanetTeam'
import LogoMiniGame from '../components/Team/LogoMiniGame/LogoMiniGame'
import SEO from '../components/Layout/SEO/SEO'

import useBreakpoint from '../hooks/useBreakpoint'

import { HeroData } from '../interfaces/common'
import { TeamProps } from '../interfaces/team'
import Routes from '../interfaces/routes.d'
import { useTranslation } from 'react-i18next'

export default function Team({ data }: TeamProps) {
  const isDesktop = useBreakpoint('sm')
  const locale = i18next.resolvedLanguage
  const dogCardList = data.allStrapiDogCards.edges
  const teamCardList = data.allStrapiEmployees.edges.filter(
    card => card.node.locale === locale
  )
  // hook needed to rerender upon language change
  const { t } = useTranslation()

  const heroData: HeroData = {
    page: Routes.TEAM,
    title: 'team.hero.title',
    title2: 'team.hero.title2',
    text: 'team.hero.text',
    arrowText: 'team.hero.arrow_text',
  }

  const planetTeam = (
    <PlanetTeam
      scale={isDesktop ? [1, 1, 1] : [1.2, 1.2, 1.2]}
      position={isDesktop ? [0, 0, 0] : [7, -7, 0]}
      rotation={[0, -Math.PI / 4, 0]}
    />
  )

  return (
    <>
      <SEO
        title="Pop Rocket | Wir sind ein besonderes Team "
        description="Mit Begeisterung für digitale Medien erkunden wir ferne Galaxien des Internets. Lerne unser Team kennen!"
        tabTitle="Team"
      />
      <Layout overflow="hidden">
        <Hero
          heroData={heroData}
          threeModel={planetTeam}
          left={isDesktop ? '20%' : '0'}
          top={isDesktop ? '-18%' : '0'}
          height={isDesktop ? '120rem' : '100rem'}
        />

        <TeamGrid content={teamCardList} />
        <LogoMiniGame />
        <DogGrid content={dogCardList} />
      </Layout>
    </>
  )
}

export const teamQuery = graphql`
  query TeamCardQuery {
    allStrapiEmployees {
      edges {
        node {
          locale
          photo {
            localFile {
              publicURL
            }
          }
          fun_fact
          job_title
          color
          order
          order_mobile
          font_size
          isPerson
          name
          locale
        }
      }
    }
    allStrapiDogCards {
      edges {
        node {
          photo {
            localFile {
              publicURL
            }
          }
          order
          name
        }
      }
    }
  }
`
