import React from 'react'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import useBreakpoint from '../../hooks/useBreakpoint'

import { TeamGridProps, TeamContent } from '../../interfaces/team'
import Crown from '../../assets/images/team/crown.png'
import theme from '../../theme/theme'
import Typography from '../common/Typography'

export default function TeamGrid({ content }: TeamGridProps): JSX.Element {
  const isDesktop = useBreakpoint('sm')
  const { t } = useTranslation()
  const defineColor = (color: string | undefined) => {
    switch (color) {
      case 'yellow':
        return theme.palette.company.prlYellow
      case 'red':
        return theme.palette.company.prlRed
      case 'blue':
        return theme.palette.company.prlBlue
      case 'orange':
        return theme.palette.company.prlOrange
    }
  }

  const defineBackgroundColor = (color: string) => {
    switch (color) {
      case 'yellow':
        return theme.palette.company.prlOrange
      case 'red':
        return theme.palette.company.prlBlue
      case 'blue':
        return theme.palette.company.prlRed
      case 'orange':
        return theme.palette.company.prlYellow
    }
  }

  const defineFontSize = (size: string) => {
    switch (size) {
      case 'large':
        if (isDesktop) {
          return '5.3rem'
        } else return '3.3rem'
      case 'medium':
        if (isDesktop) {
          return '4.3rem'
        } else return '2.3rem'
      case 'small':
        if (isDesktop) {
          return '3.3rem'
        } else return '1.5rem'
    }
  }

  const defineLineHeight = (size: string) => {
    switch (size) {
      case 'large':
        if (isDesktop) {
          return '5.3rem'
        } else return '3.5rem'
      case 'medium':
        if (isDesktop) {
          return '4.2rem'
        } else return '3.2rem'
      case 'small':
        if (isDesktop) {
          return '3.2rem'
        } else return '2.2rem'
    }
  }
  return (
    <section>
      <StyledTeamGridContainer id="main-content">
        {content.map((card: { node: TeamContent }) =>
          card.node.isPerson ? (
            <StyledTeamGridTile
              key={uuidv4()}
              isPerson={card.node.isPerson}
              order={card.node.order}
              orderMobile={card.node.order_mobile}
              fontSize={defineFontSize(card.node.font_size)}
              lineHeight={defineLineHeight(card.node.font_size)}
              color={defineColor(card.node.color)}
              fact={card.node.fun_fact}
            >
              {card.node.photo && card.node.name === 'Dominik' && (
                <>
                  <StyledWhamCrown
                    src={Crown}
                    color={defineColor(card.node.color)}
                    alt="Team member photo"
                    height="320"
                    width="320"
                  />
                  <StyledTooltip className="wham-tooltip">
                    <p>{t('team.crown')}</p>
                  </StyledTooltip>
                </>
              )}
              <StyledPhotoContainer
                className="person-photo"
                color={defineColor(card.node.color)}
                backgroundColor={defineBackgroundColor(card.node.color)}
              >
                <StyledCardBack>
                  {card.node.photo && (
                    <StyledEmployeePhoto
                      src={card.node.photo.localFile.publicURL}
                      color={defineColor(card.node.color)}
                      alt="Team member photo"
                      height="320"
                      width="320"
                    />
                  )}
                  <StyledEmployeeFunFact
                    type={isDesktop ? 'h3Mobile' : 'copyBig'}
                  >
                    <StyledQuotationMark>“</StyledQuotationMark>
                    {card.node.fun_fact}
                  </StyledEmployeeFunFact>
                </StyledCardBack>
              </StyledPhotoContainer>
              <StyledCardFront>
                <StyledEmployeeData
                  type={isDesktop ? 'h3Mobile' : 'copyBig'}
                  isName={true}
                >
                  {card.node.name}
                </StyledEmployeeData>
                <StyledEmployeeData
                  type={isDesktop ? 'h3Mobile' : 'copyBig'}
                  isName={false}
                >
                  {card.node.job_title}
                </StyledEmployeeData>
              </StyledCardFront>
            </StyledTeamGridTile>
          ) : (
            <StyledTeamGridTile
              key={uuidv4()}
              isPerson={card.node.isPerson}
              order={card.node.order}
              orderMobile={card.node.order_mobile}
              fontSize={defineFontSize(card.node.font_size)}
              lineHeight={defineLineHeight(card.node.font_size)}
              color={defineColor(card.node.color)}
              fact={card.node.fun_fact}
            >
              {card.node.fun_fact}
            </StyledTeamGridTile>
          )
        )}
      </StyledTeamGridContainer>
    </section>
  )
}

const StyledTeamGridContainer = styled.div`
  position: relative;
  height: 100%;
  display: grid;
  z-index: 2;
  width: 100%;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(auto-fill, max-content);

  justify-content: center;
  justify-items: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 90vw;
    max-width: 920px;
    margin: 3rem auto 0;
    padding: 0 auto;
    grid-template-columns: 33% 34% 33%;
    grid-gap: 2rem;
  }
`
const StyledQuotationMark = styled.span`
  font-size: 4rem;
  position: absolute;
  top: -2rem;
  left: -1rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    font-size: 5.3rem;
  }
`

const StyledEmployeeFunFact = styled(Typography)`
  color: ${({ theme }) => theme.palette.global.white};
  line-height: 2.1rem;
  font-weight: 700;
  position: relative;
  padding: 0 0 0 1rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    line-height: 3.2rem;
    padding: 0 0 0 2rem;
  }
`

const StyledTeamGridTile = styled.div<{
  order: number
  orderMobile: number
  isPerson: boolean
  fontSize: string | undefined
  lineHeight: string | undefined
  color: string | undefined
  fact: string
}>`
  display: ${({ fact, isPerson }) =>
    (fact === ' ' || fact === null) && !isPerson ? 'none' : 'flex'};
  align-items: ${({ isPerson }) => (!isPerson ? 'center' : 'flex-start')};
  justify-content: ${({ isPerson }) =>
    !isPerson ? 'center' : 'space-between'};
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  min-height: 25rem;
  height: 100%;
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  font-weight: 700;
  color: ${({ theme }) => theme.palette.global.accent};
  background-color: ${({ isPerson, theme }) =>
    isPerson ? theme.palette.global.white : 'transparent'};
  box-shadow: ${({ isPerson }) =>
    isPerson ? '0px 13px 40px 5px rgba(116, 134, 169, 0.2)' : 'none'};
  border-radius: 4rem;
  text-align: left;
  padding: 2rem 2rem;
  order: ${({ orderMobile }) => orderMobile};
  position: relative;
  cursor: ${({ isPerson }) => (isPerson ? 'pointer' : 'auto')};

  :hover .person-photo {
    clip-path: circle(30rem);
  }

  :hover .wham-tooltip {
    opacity: 1;
    transform: translateX(3rem);
    ${({ theme }) => theme.breakpoints.queries.sm} {
      transform: translateX(6rem);
    }
  }

  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 4rem 3rem;
    max-height: 40rem;
    display: flex;
    order: ${({ order }) => order};
  }
`
const StyledCardFront = styled.div`
  margin: 12rem 0 0 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin: 20rem 0 0 0;
  }
`
const StyledCardBack = styled.div`
  padding: 2rem 3rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: 4rem 3rem;
  }
`

const StyledEmployeePhoto = styled.img<{
  color: string | undefined
}>`
  border-radius: 4rem;
  width: 11rem;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin: 0 0 2rem -1.4rem;
  height: auto;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 16rem;
    margin: 0 0 2rem;
  }
`

const StyledPhotoContainer = styled.div<{
  backgroundColor: string | undefined
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4rem;
  margin: 0 0 2rem;
  clip-path: circle(5.4rem at 7.1rem 7.4rem);
  transition: clip-path 1.2s;
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    clip-path: circle(8rem at 11rem 12rem);
  }
`

const StyledEmployeeData = styled(Typography)<{ isName: boolean }>`
  line-height: 2.4rem;
  color: ${({ theme }) => theme.palette.global.accentLight};
  font-weight: ${({ isName }) => (isName ? '700' : '400')};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    line-height: 3.2rem;
  }
`

const StyledWhamCrown = styled.img`
  position: absolute;
  top: -1rem;
  left: 5rem;
  width: 7rem;
  height: auto;
  z-index: 3;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    top: -1rem;
    left: 7.7rem;
    width: 11rem;
  }
`
const StyledTooltip = styled.div`
  opacity: 0;
  z-index: 2;
  font-size: 1.2rem;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.company.prlYellow};
  color: ${({ theme }) => theme.palette.global.white};
  width: 13.5rem;
  border-radius: 0 3rem 3rem 0;
  top: 2.6rem;
  left: 6.6rem;
  p {
    margin: -0.5rem 0 -0.5rem 1.4rem;
  }
  ${({ theme }) => theme.breakpoints.queries.sm} {
    top: 4.7rem;
    left: 9.2rem;
    font-size: 1.5rem;
    width: 16.3rem;
    p {
      margin: -0.8rem 0 -0.8rem 1.7rem;
    }
  }
  transition: opacity 0.6s ease-in, transform 0.6s ease-in;
`
