import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import confetti from 'canvas-confetti'

import LogoPiece from './LogoPiece/LogoPiece'
import LogoPieceText from './LogoPiece/LogoPieceText'
import Typography from '../../common/Typography'
import theme from '../../../theme/theme'

import useBreakpoint from '../../../hooks/useBreakpoint'

import './LogoPiece/LogoPieceKeyframes.css'
import LogoCompletedWhite from '../../../assets/images/team/logo_completed_white.png'
import FireTextIcon from '../../../assets/images/team/texticons/fire.svg'
import FireIcon from '../../../assets/images/team/fire.svg'
import UmbrellaTextIcon from '../../../assets/images/team/texticons/umbrella.svg'
import UmbrellaIcon from '../../../assets/images/team/umbrella.svg'
import LightbulbTextIcon from '../../../assets/images/team/texticons/lightbulb.svg'
import LightbulbIcon from '../../../assets/images/team/lightbulb.svg'
import SecurityTextIcon from '../../../assets/images/team/texticons/security.svg'
import SecurityIcon from '../../../assets/images/team/security.svg'
import CompassTextIcon from '../../../assets/images/team/texticons/compas.svg'
import CompassIcon from '../../../assets/images/team/compass.svg'
import AnchorTextIcon from '../../../assets/images/team/texticons/anchor.svg'
import AnchorIcon from '../../../assets/images/team/anchor.svg'
import TeamLine from '../../../assets/images/team/line.svg'

const logoPieces = [
  {
    index: 0,
    color: 'orange',
    textColor: 'orange',
    textIcon: UmbrellaTextIcon,
    icon: UmbrellaIcon,
    class: 'orange',
    title: 'team.logogame.pieces.piece2.title',
    text: 'team.logogame.pieces.piece2.text',
    path: 'M130 74.5L0 0V150L130 74.5Z',
  },
  {
    index: 1,
    color: 'red',
    textColor: 'red',
    textIcon: LightbulbTextIcon,
    icon: LightbulbIcon,
    class: 'red',
    title: 'team.logogame.pieces.piece3.title',
    text: 'team.logogame.pieces.piece3.text',
    path: 'M0 76L130 150.5L130 0.50029L0 76Z',
  },
  {
    index: 2,
    color: 'blue',
    textColor: 'blue',
    textIcon: SecurityTextIcon,
    icon: SecurityIcon,
    class: 'blue',
    title: 'team.logogame.pieces.piece4.title',
    text: 'team.logogame.pieces.piece4.text',
    path: 'M130 74.5L0 0V150L130 74.5Z',
  },
  {
    index: 3,
    color: 'lightgrey',
    textColor: 'darkgrey',
    textIcon: CompassTextIcon,
    icon: CompassIcon,
    class: 'lightgrey',
    title: 'team.logogame.pieces.piece5.title',
    text: 'team.logogame.pieces.piece5.text',
    path: 'M0 224.5L130 150V300L0 224.5Z',
  },
  {
    index: 4,
    color: 'middlegrey',
    textColor: 'blue',
    textIcon: AnchorTextIcon,
    icon: AnchorIcon,
    class: 'middlegrey',
    title: 'team.logogame.pieces.piece6.title',
    text: 'team.logogame.pieces.piece6.text',
    path: 'M130 76L0 150.5L0 0.5L130 76Z',
  },
  {
    index: 5,
    color: 'yellow',
    textColor: 'yellow',
    textIcon: FireTextIcon,
    icon: FireIcon,
    class: 'yellow',
    title: 'team.logogame.pieces.piece1.title',
    text: 'team.logogame.pieces.piece1.text',
    path: 'M0 74.5L130 0V150L0 74.5Z',
  }
]

export default function LogoMiniGame(): JSX.Element {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [solved, setSolved] = useState<Array<number | null>>([])
  const [hovered, setHovered] = useState(false)
  const [showContainerText, setShowContainerText] = useState(true)
  const isDesktop = useBreakpoint('sm')
  const { t } = useTranslation()

  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const defineColor = (color: string | undefined) => {
    switch (color) {
      case 'yellow':
        return theme.palette.company.prlYellow
      case 'red':
        return theme.palette.company.prlRed
      case 'blue':
        return theme.palette.company.prlBlue
      case 'orange':
        return theme.palette.company.prlOrange
      case 'lightgrey':
        return theme.palette.global.grey
      case 'middlegrey':
        return theme.palette.company.prlLogoGrey
    }
  }

  const defineTextColor = (textColor: string | undefined) => {
    switch (textColor) {
      case 'yellow':
        return theme.palette.company.prlYellow
      case 'red':
        return theme.palette.company.prlRed
      case 'blue':
        return theme.palette.company.prlBlue
      case 'orange':
        return theme.palette.company.prlOrange
      case 'darkgrey':
        return theme.palette.global.darkGrey
    }
  }

  function handleClick(index: number): void {
    setShowContainerText(false)
    if (solved.indexOf(index) == -1) {
      setSolved([...solved, index])
    }
    setActiveIndex(index)
  }

  const toggleHover = (index: React.SetStateAction<number | null>) => {
    setActiveIndex(index)
    setHovered(!hovered)
  }

  useEffect(() => {
    return () => clearTimeout(timerRef.current as NodeJS.Timeout)
  }, [])

  useEffect(() => {
    if (solved.length == 6) {
      timerRef.current = setTimeout(
        () =>
          confetti({
            particleCount: 150,
            spread: 90,
            ticks: 200,
            colors: [
              theme.palette.company.prlBlue,
              theme.palette.company.prlRed,
              theme.palette.company.prlOrange,
              theme.palette.company.prlYellow,
            ],
          }),
        300
      )
    }
  }, [solved])

  return (
    <section>
      <StyledMiniGameContainer>
        <StyledLogoContainer>
          <StyledLogoContainerImage
            src={LogoCompletedWhite}
            height="300"
            width="260"
          />
          {logoPieces.map((logoPiece, index) => (
            <div key={index}>
              <LogoPiece
                key={index}
                index={logoPiece.index}
                color={defineColor(logoPiece.color)}
                pieceClass={logoPiece.class}
                icon={logoPiece.icon}
                activeIndex={logoPiece.index}
                solvedPiece={solved.some(item => item === logoPiece.index)}
                floatingPiece={
                  solved.some(item => item === logoPiece.index) ? false : true
                }
                showIcon={logoPiece.index === activeIndex}
                onClick={handleClick}
                onMouseEnter={toggleHover}
                //onMouseLeave={toggleHover}
              />

              <StyledLogoPieceTextContainer
                showText={
                  logoPiece.index == activeIndex &&
                  solved.some(item => item === logoPiece.index)
                }
              >
                <LogoPieceText
                  textColor={defineTextColor(logoPiece.textColor)}
                  title={logoPiece.title}
                  text={logoPiece.text}
                  textIcon={logoPiece.textIcon}
                />
              </StyledLogoPieceTextContainer>
              </div>
          ))}
          <StyledLogoMainText
            id="logo-container-text"
            showContainerText={showContainerText}
          >
            <StyledLogoTitle type="copyBig">
              {t('team.logogame.container_title')}
            </StyledLogoTitle>
            <StyledLogoParagraph type={isDesktop ? 'h3Mobile' : 'copyBig'}>
              {t('team.logogame.container_text')}
            </StyledLogoParagraph>
          </StyledLogoMainText>
          <StyledClickHint
            showContainerText={showContainerText}
            id="click-hint"
          >
            {t('team.logogame.click')}
            <StyledClickLine src={TeamLine}></StyledClickLine>
          </StyledClickHint>
        </StyledLogoContainer>
      </StyledMiniGameContainer>
    </section>
  )
}

const StyledMiniGameContainer = styled.article`
  margin: 25rem 0;
  @media (min-width: 768px) {
    margin: 25rem 0 15rem;
  }
  @media (min-width: 850px) {
    margin: 15rem 0;
  }
`

const StyledLogoContainer = styled.div`
  position: relative;
  z-index: 0;
  cursor: pointer;
  will-change: transform;
`
const StyledLogoContainerImage = styled.img`
  will-change: transform;
  width: 80%;
  height: auto;
  /* disabled to avoid flickering */
  /* -webkit-filter: drop-shadow(0px 13px 40px rgba(116, 134, 169, 0.2)) */
  @media (min-width: 850px) {
    -webkit-filter: drop-shadow(0px 13px 40px rgba(116, 134, 169, 0.2));
    width: 100%;
  }
`
const StyledLogoMainText = styled.div<{ showContainerText: boolean }>`
  position: absolute;
  top: 5.5rem;
  max-width: 14rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  transition: opacity ease-in 0.3s;
  z-index: 0;
  color: ${({ theme }) => theme.palette.global.accentLight};
  opacity: ${({ showContainerText }) => (showContainerText ? '1' : '0')};
  @media (min-width: 850px) {
    top: 7rem;
    max-width: 18rem;
  }
`

const StyledLogoTitle = styled(Typography)`
  font-weight: 850;
  font-size: 1.6rem;
  @media (min-width: 850px) {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }
`

const StyledLogoParagraph = styled(Typography)`
  font-size: 1.6rem;
  line-height: 2.5rem;
  font-weight: 400;
  @media (min-width: 850px) {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }
`

const StyledClickHint = styled.div<{ showContainerText: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 37rem;
  left: -3rem;
  color: ${({ theme }) => theme.palette.global.accent};
  font-weight: 850;
  font-size: 2.4rem;
  line-height: 5.2rem;
  transition: opacity ease-in 0.3s;
  opacity: ${({ showContainerText }) => (showContainerText ? '1' : '0')};

  @media (min-width: 850px) {
    top: 17rem;
    left: -18rem;
  }
`
const StyledClickLine = styled.img`
  position: absolute;
  top: -4rem;
  left: 3rem;
`
const StyledLogoPieceTextContainer = styled.div<{ showText: boolean }>`
  position: absolute;
  opacity: ${({ showText }) => (showText ? '1' : '0')};
  z-index: ${({ showText }) => (showText ? '100' : '99')};
  transition: opacity 0.3s ease-in;
  background: transparent;
`
