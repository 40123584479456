import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { DreiGLTF } from '../../../interfaces/three'
import { useFrame } from '@react-three/fiber'

export default function PlanetReference({
  ...props
}: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/models/glb/HeroPlanet.glb') as DreiGLTF
  const planetRef = useRef<THREE.Group>()
  useFrame(() => {
    if (planetRef.current) {
      planetRef.current.rotation.y += 0.001
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group ref={planetRef}>
        <primitive object={nodes.Root_1} />
        <skinnedMesh
          name="Planet_L_1"
          frustumCulled={false}
          geometry={nodes.Planet_L_1.geometry}
          material={nodes.Planet_L_1.material}
          skeleton={nodes.Planet_L_1.skeleton}
        />
        <skinnedMesh
          name="Roads_R"
          frustumCulled={false}
          geometry={nodes.Roads_R.geometry}
          material={nodes.Roads_R.material}
          skeleton={nodes.Roads_R.skeleton}
        />
        <skinnedMesh
          name="StaticBuildings_R"
          frustumCulled={false}
          geometry={nodes.StaticBuildings_R.geometry}
          material={nodes.StaticBuildings_R.material}
          skeleton={nodes.StaticBuildings_R.skeleton}
        />
        <skinnedMesh
          name="Trees_R"
          frustumCulled={false}
          geometry={nodes.Trees_R.geometry}
          material={nodes.Trees_R.material}
          skeleton={nodes.Trees_R.skeleton}
        />
        <skinnedMesh
          name="Planet_R_1"
          frustumCulled={false}
          geometry={nodes.Planet_R_1.geometry}
          material={nodes.Planet_R_1.material}
          skeleton={nodes.Planet_R_1.skeleton}
        />
        <skinnedMesh
          name="Roads_L"
          frustumCulled={false}
          geometry={nodes.Roads_L.geometry}
          material={nodes.Roads_L.material}
          skeleton={nodes.Roads_L.skeleton}
        />
        <skinnedMesh
          name="Rocket"
          frustumCulled={false}
          geometry={nodes.Rocket.geometry}
          material={materials.M_Rocket}
          skeleton={nodes.Rocket.skeleton}
        />
        <skinnedMesh
          name="Rocket_Holder"
          frustumCulled={false}
          geometry={nodes.Rocket_Holder.geometry}
          material={materials.M_MechanicalArm}
          skeleton={nodes.Rocket_Holder.skeleton}
        />
        <skinnedMesh
          name="RocketWindows"
          frustumCulled={false}
          geometry={nodes.RocketWindows.geometry}
          material={materials.M_RocketWindow}
          skeleton={nodes.RocketWindows.skeleton}
        />
        <skinnedMesh
          name="StaticBuildings_L"
          frustumCulled={false}
          geometry={nodes.StaticBuildings_L.geometry}
          material={nodes.StaticBuildings_L.material}
          skeleton={nodes.StaticBuildings_L.skeleton}
        />
        <skinnedMesh
          name="Trees_L"
          frustumCulled={false}
          geometry={nodes.Trees_L.geometry}
          material={nodes.Trees_L.material}
          skeleton={nodes.Trees_L.skeleton}
        />
        <primitive object={nodes.Root_02} />
        <group
          name="Armature-Human004"
          position={[4.97, -5.41, 6.57]}
          rotation={[0.92, -1, -1.36]}
          scale={0.35}
        >
          <primitive object={nodes.BodyCenter} />
          <skinnedMesh
            name="Human004"
            frustumCulled={false}
            geometry={nodes.Human004.geometry}
            material={nodes.Human004.material}
            skeleton={nodes.Human004.skeleton}
          />
        </group>
        <group
          name="Armature-Human005"
          position={[2.91, 7.1, 6.17]}
          rotation={[0.66, -0.24, -0.33]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_1} />
          <skinnedMesh
            name="Human005"
            frustumCulled={false}
            geometry={nodes.Human005.geometry}
            material={nodes.Human005.material}
            skeleton={nodes.Human005.skeleton}
          />
        </group>
        <group
          name="Armature-Human006"
          position={[6.56, 5.58, 4.8]}
          rotation={[0.31, -0.38, -0.85]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_2} />
          <skinnedMesh
            name="Human006"
            frustumCulled={false}
            geometry={nodes.Human006.geometry}
            material={nodes.Human006.material}
            skeleton={nodes.Human006.skeleton}
          />
        </group>
        <group
          name="Armature-Human007"
          position={[0.87, 6.31, 7.52]}
          rotation={[1, 1.29, -0.17]}
          scale={0.35}
        >
          <primitive object={nodes.BodyCenter_3} />
          <skinnedMesh
            name="Human007"
            frustumCulled={false}
            geometry={nodes.Human007.geometry}
            material={nodes.Human007.material}
            skeleton={nodes.Human007.skeleton}
          />
        </group>
        <group
          name="Armature-Human008"
          position={[-1.07, -5.93, 7.78]}
          rotation={[-0.81, 0.68, 2.96]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_4} />
          <skinnedMesh
            name="Human008"
            frustumCulled={false}
            geometry={nodes.Human008.geometry}
            material={nodes.Human008.material}
            skeleton={nodes.Human008.skeleton}
          />
        </group>
        <group
          name="Armature-Human009"
          position={[8.2, 1.4, 5.26]}
          rotation={[-0.52, -0.55, -1.71]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_5} />
          <skinnedMesh
            name="Human009"
            frustumCulled={false}
            geometry={nodes.Human009.geometry}
            material={nodes.Human009.material}
            skeleton={nodes.Human009.skeleton}
          />
        </group>
        <group
          name="Armature-Human010"
          position={[7.97, 5.76, 0.46]}
          rotation={[0.6, 0.36, -1.02]}
          scale={0.35}
        >
          <primitive object={nodes.BodyCenter_6} />
          <skinnedMesh
            name="Human010"
            frustumCulled={false}
            geometry={nodes.Human010.geometry}
            material={nodes.Human010.material}
            skeleton={nodes.Human010.skeleton}
          />
        </group>
        <group
          name="Armature-Human011"
          position={[0.11, 7.79, 6.02]}
          rotation={[-2.99, 1.47, -2.65]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_7} />
          <skinnedMesh
            name="Human011"
            frustumCulled={false}
            geometry={nodes.Human011.geometry}
            material={nodes.Human011.material}
            skeleton={nodes.Human011.skeleton}
          />
        </group>
        <group
          name="Armature-Human012"
          position={[0.95, -7.56, 6.27]}
          rotation={[2.43, 0.18, -0.14]}
          scale={0.35}
        >
          <primitive object={nodes.BodyCenter_8} />
          <skinnedMesh
            name="Human012"
            frustumCulled={false}
            geometry={nodes.Human012.geometry}
            material={nodes.Human012.material}
            skeleton={nodes.Human012.skeleton}
          />
        </group>
        <group
          name="Armature-Human013"
          position={[0.72, 8.88, 4.18]}
          rotation={[0.5, 0.8, -0.07]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_9} />
          <skinnedMesh
            name="Human013"
            frustumCulled={false}
            geometry={nodes.Human013.geometry}
            material={nodes.Human013.material}
            skeleton={nodes.Human013.skeleton}
          />
        </group>
        <group
          name="Armature-Human014"
          position={[-0.84, 4.29, 8.82]}
          rotation={[1.04, 1.06, 0.1]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_10} />
          <skinnedMesh
            name="Human014"
            frustumCulled={false}
            geometry={nodes.Human014.geometry}
            material={nodes.Human014.material}
            skeleton={nodes.Human014.skeleton}
          />
        </group>
        <group
          name="Armature-Human015"
          position={[7.34, -0.07, 6.57]}
          rotation={[-1.05, -0.38, -2.22]}
          scale={[0.35, 0.35, 0.35]}
        >
          <primitive object={nodes.BodyCenter_11} />
          <skinnedMesh
            name="Human015"
            frustumCulled={false}
            geometry={nodes.Human015.geometry}
            material={nodes.Human015.material}
            skeleton={nodes.Human015.skeleton}
          />
        </group>
        <primitive object={nodes.Root_2} />
      </group>
    </group>
  )
}
