import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Typography from '../../../common/Typography'

interface Text {
  textColor: string | undefined
  title: string
  text: string
  textIcon: string
}

export default function LogoPieceText(props: Text): JSX.Element {
  const { t } = useTranslation()
  return (
    <StyledLogoPieceText>
      <StyledLogoPieceTitle type="h3Mobile" textColor={props.textColor}>
        <StyledLogoPieceTextIcon src={props.textIcon} className="texticon" />
        {t(props.title)}
      </StyledLogoPieceTitle>
      <StyledLogoPieceParagraph type="copyBig">
        {t(props.text)}
      </StyledLogoPieceParagraph>
    </StyledLogoPieceText>
  )
}

const StyledLogoPieceText = styled.div`
  position: absolute;
  opacity: 1;
  background-color: transparent;
  width: 26rem;
  top: 11rem;
  left: 0;
  text-align: left;
  transition: opacity 0.3s ease-in-out;
  cursor: auto;

  @media (min-width: 850px) {
    top: 6rem;
    left: 0rem;
  }
`
const StyledLogoPieceTitle = styled(Typography)<{
  textColor: string | undefined
}>`
  color: ${({ textColor }) => textColor};
  font-weight: 700;
  height: 4rem;
`
const StyledLogoPieceParagraph = styled(Typography)`
  height: 8rem;
`
const StyledLogoPieceTextIcon = styled.img`
  margin-right: 2rem;
`
