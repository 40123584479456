import React, { ReactElement, useState } from 'react'
import styled from 'styled-components/macro'

interface LogoPieceContent {
  onMouseEnter: (index: React.SetStateAction<number | null>) => void
  index: number
  color: string | undefined
  pieceClass: string
  icon: string
  activeIndex: number
  solvedPiece: boolean
  floatingPiece: boolean
  showIcon: boolean
  onClick: (i: number) => void
}

export default function LogoPiece({
  index,
  pieceClass,
  color,
  icon,
  activeIndex,
  solvedPiece,
  floatingPiece,
  showIcon,
  onClick,
  onMouseEnter,
}: LogoPieceContent): ReactElement {
  const [, setActiveIndex] = useState<number | null>(null)
  const [, setShowText] = useState(false)
  const [shouldShowIcon, setShouldShowIcon] = useState(true)
  const [, setHovered] = useState(false)

  function clickHandler(index: number): number {
    setShouldShowIcon(false)
    setActiveIndex(activeIndex)
    if (index == activeIndex) {
      setShowText(true)
    }
    return index
  }

  function hoverHandler(index: number): number {
    setHovered(true)
    setActiveIndex(activeIndex)
    if (index == activeIndex) {
      setShowText(true)
    }
    return index
  }

  return (
    <StyledShadow isSolved={solvedPiece} isFloating={floatingPiece}>
      <StyledLogoPieceContainer
        className={`${pieceClass}`}
        activeIndex={activeIndex}
        color={color}
        isSolved={solvedPiece}
        isFloating={floatingPiece}
        onMouseEnter={() => {
          onMouseEnter(hoverHandler(index))
        }}
        onClick={() => {
          onClick(clickHandler(index))
        }}
      >
        <StyledLogoIcon
          src={icon}
          showIcon={shouldShowIcon || showIcon}
          color={pieceClass}
          onMouseEnter={() => {
            onMouseEnter(hoverHandler(index))
          }}
          onClick={() => {
            onClick(clickHandler(index))
          }}
        />
      </StyledLogoPieceContainer>
    </StyledShadow>
  )
}

const StyledShadow = styled.div<{
  isSolved: boolean
  isFloating: boolean
}>`
  -webkit-filter: ${({ isSolved }) =>
    isSolved ? 'none' : ' drop-shadow(0px 8px 16px rgba(0, 34, 102, 0.2));'};
  box-shadow: ${({ isSolved }) =>
    isSolved ? 'none' : 'drop-shadow(0px 8px 16px rgba(0, 34, 102, 0.2))'};
  top: 0rem;
  position: absolute;

  /* removes the flickering of the logo game piece */
  transform: translate3d(0, 0, 0);
`

const StyledLogoPieceContainer = styled.div<{
  activeIndex: number
  isSolved: boolean
  isFloating: boolean
  color: string | undefined
}>`
  position: relative;
  will-change: transform;
  transition: transform 0.3s ease-in;
  top: 0rem;
  left: 34.1rem;
  animation-duration: ${({ isFloating }) => (isFloating ? '3s' : 'none')};
  animation-iteration-count: ${({ isFloating }) =>
    isFloating ? 'infinite' : 'none'};
  animation-timing-function: ${({ isFloating }) =>
    isFloating ? 'ease-in-out' : 'none'};
  overflow: hidden;
  clip-path: polygon(50% 101%, -1% -1%, 101% -1%);
  background-color: ${({ color }) => color};
  width: 15rem;
  height: 13rem;
  &.yellow {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-31.12rem, 0.8rem) scale(0.8) rotate(-30deg);'
        : 'translate(-37.5rem, 22rem) scale(0.5) rotate(-30deg);'};
    z-index: ${({ activeIndex }) => (activeIndex == 0 ? '94' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-yellow' : 'none'};
    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-32rem, 2.8rem) scale(1) rotate(-30deg);'
          : 'translate(-51rem, 2.5rem) scale(0.7) rotate(-30deg);'};
    }
  }
  &.orange {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-25.9rem, -2.1rem) scale(0.8) rotate(150deg);'
        : 'translate(-29rem, -15rem) scale(0.5) rotate(100deg);'};

    z-index: ${({ activeIndex }) => (activeIndex == 1 ? '95' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-orange' : 'none'};

    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-25.5rem, -1rem) scale(1) rotate(150deg);'
          : 'translate(-7rem, 0rem) scale(0.7) rotate(100deg);'};
    }
  }
  &.red {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-23.3rem, 5.37rem) scale(0.8) rotate(90deg);'
        : 'translate(-16rem, -12rem) scale(0.5) rotate(30deg);'};
    z-index: ${({ activeIndex }) => (activeIndex == 2 ? '96' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-red' : 'none'};

    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-22.2rem, 8.36rem) scale(1) rotate(90deg);'
          : 'translate(7rem, 5rem) scale(0.7) rotate(30deg);'};
    }
  }

  &.blue {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-25.88rem, 9.9rem) scale(0.8) rotate(150deg);'
        : 'translate(-16rem, 19rem) scale(0.5) rotate(140deg);'};

    z-index: ${({ activeIndex }) => (activeIndex == 3 ? '97' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-blue' : 'none'};
    animation-duration: ${({ isFloating }) => (isFloating ? '3s' : 'none')};

    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-25.35rem, 14.1rem) scale(1) rotate(150deg);'
          : 'translate(-3rem, 20rem) scale(0.7) rotate(140deg);'};
      animation-duration: ${({ isFloating }) => (isFloating ? '4s' : 'none')};
    }
  }
  &.lightgrey {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-31.2rem, 12.9rem) scale(0.8) rotate(-30deg);'
        : 'translate(-39rem, -9rem) scale(0.5) rotate(-75deg);'};
    z-index: ${({ activeIndex }) => (activeIndex == 4 ? '98' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-lightgrey' : 'none'};
    animation-duration: ${({ isFloating }) => (isFloating ? '3.5s' : 'none')};

    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-32rem, 17.8rem) scale(1) rotate(-30deg);'
          : 'translate(-50rem, 26rem) scale(0.7) rotate(-75deg);'};
    }
  }

  &.middlegrey {
    transform: ${({ isSolved }) =>
      isSolved
        ? 'translate(-36.37rem, 3.8rem) scale(0.8) rotate(150deg);'
        : 'translate(-26rem, 23rem) scale(0.5) rotate(130deg);'};

    z-index: ${({ activeIndex }) => (activeIndex == 5 ? '99' : '100')};
    animation-name: ${({ isFloating }) =>
      isFloating ? 'floating-middlegrey-mobile' : 'none'};
    animation-duration: ${({ isFloating }) => (isFloating ? '4s' : 'none')};
    @media (min-width: 850px) {
      transform: ${({ isSolved }) =>
        isSolved
          ? 'translate(-38.4rem, 6.6rem) scale(1) rotate(150deg);'
          : 'translate(-68rem, 9rem) scale(0.7) rotate(130deg);'};
      animation-name: ${({ isFloating }) =>
        isFloating ? 'floating-middlegrey-mobile' : 'none'};
      animation-duration: ${({ isFloating }) => (isFloating ? '4s' : 'none')};
    }
  }
`

const StyledLogoIcon = styled.img<{ showIcon: boolean; color: string }>`
  position: absolute;
  top: 3.2rem;
  left: 6rem;
  opacity: 0;
  transform: ${({ color }) =>
    color === 'orange' || color === 'blue' || color === 'middlegrey'
      ? 'rotate(210deg);'
      : color === 'red'
      ? 'rotate(-90deg);'
      : color === 'yellow'
      ? 'rotate(30deg);'
      : 'scale(1.2);'};
  opacity: ${({ showIcon }) => (showIcon ? '1' : '0')};
  transition: opacity 0.3s ease-in;
`
